// src/App.tsx
import React, { useEffect } from "react";
import "./App.css";
import "./global.scss";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";

import { useNavigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Request from "./pages/request/Request";
import RequestDetails from "./pages/request/teacher/RequestDetails";
import TuitionClasses from "./pages/tuition-classes/TuitionClasses";
import TeacherList from "./pages/teacher/TeacherList";
import StudentList from "./pages/student/StudentList";
import ParentList from "./pages/parent/ParentList";
import { ToastProvider } from "./components/toasts/ToastContext";
import ToastInitializer from "./components/toasts/ToastInitializer";
import AdminList from "./pages/admin/AdminList";
import TeacherDetails from "./pages/teacher/TeacherDetails";
import CouponList from "./pages/coupon/CouponList";
import InfoSettings from "./pages/info/Info";
import GuruCoin from "./pages/guru-coin/GuruCoin";
import GuruCoinTransaction from "./pages/transactions/guru-coin-transactions/GuruCoinTransaction";
import PaymentsPage from "./pages/transactions/payments/Payments";
import TuitionJobPostsPage from "./pages/tuition/job-posts/TuitionJobPosts";
import TermsConditionsForTeachers from "./pages/page-contents/terms-conditions/TermsConditionsForTeachers";
import TermsConditionsForParents from "./pages/page-contents/terms-conditions/TermsConditionsForParents";
import { get } from "./redux/ApiHelper";
import { AdminRole } from "./enums/admin-roles";
import { useDispatch, useSelector } from "react-redux";
import { setSignedAdmin } from "./redux/slices/AdminSlice";
import { RootState } from "./redux/store";
import PayoutsPage from "./pages/transactions/payouts/Payouts";
import PrivacyPolicy from "./pages/page-contents/terms-conditions/PrivacyPolicy";
import RefundPolicy from "./pages/page-contents/terms-conditions/RefundPolicy";
import TrainingVideo from "./pages/training-video/TraningVideo";
import BannerSettings from "./pages/banner-settings/BannerSettings";


const useAuthCheck = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/login");
    } else {
      // You can add token validation or refresh logic here if necessary
    }
  }, [navigate]); // Trigger this effect whenever the component mounts

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const checkValidAccess = async () => {
      await get("/admin/auth/valid-access")
        .then((response) => {
          dispatch(
            setSignedAdmin({
              email: response.email,
              role: response.role,
            })
          );
        })
        .catch((error) => {
          localStorage.clear();
          navigate("/login");
          console.error("Error in accessing:", error);
        })
        .finally(() => {
          // dispatch(stopLoading());
        });
    };
    if (token) checkValidAccess();
  }, []);
};

const routePaths: {
  path: string;
  component: React.ReactNode;
  role: AdminRole;
}[] = [
  {
    path: "/",
    component: <Dashboard />,
    role: AdminRole.ADMIN, //Both admin and super admin
  },
  {
    path: "/teacher",
    component: <TeacherList />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/teacher/:id",
    component: <TeacherDetails />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/student",
    component: <StudentList />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/parent",
    component: <ParentList />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/tuition-requests",
    component: <Request />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/request/:id",
    component: <RequestDetails />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/tuition-classes",
    component: <TuitionClasses />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/admin",
    component: <AdminList />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/coupon",
    component: <CouponList />,
    role: AdminRole.SUPER_ADMIN, //only super admin access
  },
  {
    path: "/info-settings",
    component: <InfoSettings />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/guru-coin",
    component: <GuruCoin />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/guru-coin-transaction",
    component: <GuruCoinTransaction />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/payments",
    component: <PaymentsPage />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/payouts",
    component: <PayoutsPage />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/tuition-job-posts",
    component: <TuitionJobPostsPage />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/terms-conditions/teachers",
    component: <TermsConditionsForTeachers />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/terms-conditions/parents",
    component: <TermsConditionsForParents />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/refund-policy",
    component: <RefundPolicy />,
    role: AdminRole.SUPER_ADMIN,
  },
  {
    path: "/train-video",
    component: <TrainingVideo />,
    role: AdminRole.ADMIN,
  },
  {
    path: "/banner",
    component: <BannerSettings />,
    role: AdminRole.ADMIN,
  },
];

const App: React.FC = () => {
  useAuthCheck(); // Custom hook to check authentication and redirect if necessary
  const { signedAdmin } = useSelector((state: RootState) => state.admin);
  return (
    // <Router>
    <div className="App">
      <ToastProvider>
        <ToastInitializer />
        <Routes>
          {/* All routes will be wrapped with Layout */}
          <Route element={<Layout />}>
            {routePaths.map((rp) => {
              if (signedAdmin.role === AdminRole.SUPER_ADMIN) {
                // Super Admin can access all routes
                return (
                  <Route key={rp.path} path={rp.path} element={rp.component} />
                );
              } else if (
                signedAdmin.role === AdminRole.ADMIN &&
                rp.role === AdminRole.ADMIN
              ) {
                // Admin can access only Admin routes
                return (
                  <Route key={rp.path} path={rp.path} element={rp.component} />
                );
              }
              return null; // Restrict access if the role doesn't match
            })}
          </Route>

          {/* Routes without layout */}
          <Route path="/login" element={<Login />} />

          {/* Catch-all Route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ToastProvider>
    </div>
    // </Router>
  );
};

export default App;
