import { useEffect, useState } from "react";
import { MdEdit, MdAdd, MdDeleteOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import TableComponent from "../../components/table/TableComponent";
import { DispatchType } from "../../interfaces/redux.interface";
import { Column } from "../../interfaces/table.interface";
import { get, put } from "../../redux/ApiHelper";
import BannerModal from "./BannerModal";
import { BannerInterface } from "../../interfaces/banner.interface";
import { BASE_URL } from "../../config";
import { FormControlLabel, Switch } from "@mui/material";

const TrainingVideo = () => {
  const dispatch: DispatchType = useDispatch();
  //    const { couponList }: { couponList: CouponInterface[] } = useSelector(
  //      (state: RootState) => state.coupon
  //    );
  const [trainingVideos, setTrainingVideos] = useState<BannerInterface[]>([]);
  const [filteredBanners, setFilteredBanners] = useState<BannerInterface[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<BannerInterface>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    // dispatch(getCoupons());
    // eslint-disable-next-line
    const getTrainingVideos = async () => {
      dispatch(startLoading());
      const res = await get("/admin/banner/all")
        .then((response) => {
          setFilteredBanners(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          dispatch(stopLoading());
        });
    };
    getTrainingVideos();
  }, [isEdit]);

  useEffect(() => {
    setFilteredBanners(filteredBanners);
  }, [filteredBanners]);

  //   const handleSearch = (query: string) => {
  //     setSearchQuery(query);
  //     const lowerQuery = query.toLowerCase();

  //     const filteredList = couponList.filter((coupon) => {
  //       return (
  //         coupon._id?.toLowerCase().includes(lowerQuery) || // Search by ID
  //         coupon.couponCode?.toLowerCase().includes(lowerQuery) || // Search by Coupon Code
  //         coupon.discountType?.toLowerCase().includes(lowerQuery) || // Search by Discount Type
  //         (coupon.discountValue?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Discount Value
  //         coupon.expiryDate?.toLowerCase().includes(lowerQuery) || // Search by Expiry Date
  //         (coupon.usageLimit?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Usage Limit
  //         (coupon.remainingUses?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Remaining Uses
  //         (coupon.isActive ? "active" : "inactive").includes(lowerQuery) // Search by Status
  //       );
  //     });

  //     setFilteredCouponList(filteredList);
  //   };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  //   Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null);
    setIsEdit(false);
  };

  const columns: Column[] = [
    { select: "forWhom", header: "Banner is for" },
    { select: "redirectUrl", header: "Redirect url", align: "left" },
    { select: "description", header: "Banner description" },
    {
      select: "imageUrlDesktop",
      header: "Desktop size banner Url",
      render: (value: boolean, rowData: BannerInterface) => (
        <div className="flex flex-col justify-center items-center">
          <p>{rowData.imageUrlDesktop}</p>
          <img
            src={rowData.imageUrlDesktop}
            alt=""
            width={200}
            height={100}
          ></img>
        </div>
      ),
    },
    {
      select: "imageUrlMobile",
      header: "Mobile size banner Url",
      render: (value: boolean, rowData: BannerInterface) => (
        <div className="flex flex-col justify-center items-center">
          <p>{rowData.imageUrlMobile}</p>
          <img
            src={rowData.imageUrlMobile}
            alt=""
            width={200}
            height={100}
          ></img>
        </div>
      ),
    },
    {
      select: "isActive",
      header: "Status",
      render: (value: boolean, rowData: BannerInterface) => (
        <div>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={rowData.isActive}
                onChange={(e) => {
                  const handleChange = async () => {
                    setIsEdit(true);
                    await put(`/admin/banner/status/${rowData._id}`, {
                      status: e.target.checked,
                    }).then((res) => {
                      // console.log(res);
                    });
                    setIsEdit(false);
                  };
                  handleChange();
                }}
              />
            }
            label={rowData.isActive ? "Active" : "Inactive"}
          />
        </div>
      ),
    },

    {
      header: "Edit",
      render: (value: boolean, rowData: BannerInterface) => (
        <div
          className="rounded-full bg-slate-200 p-2 text-yellow-500 cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setIsEdit(true);
            setIsModalOpen(true);
          }}
        >
          <MdEdit className="m-auto" />
        </div>
      ),
    },

    {
      header: "Delete",
      render: (value: boolean, rowData: BannerInterface) => (
        <div
          className="rounded-full bg-slate-200 p-2 text-yellow-500 cursor-pointer"
          onClick={() => {
            const deleteItem = async () => {
              setIsEdit(true);

              const res = await fetch(
                `${BASE_URL}/admin/banner/delete/${rowData._id}`,
                {
                  method: "DELETE",
                }
              );
              const data = await res.json();
              // console.log(data);
              setIsEdit(false);
            };
            deleteItem();
          }}
        >
          <MdDeleteOutline className="m-auto" />
        </div>
      ),
    },
  ];

  return (
    <div className="coupon table-container">
      <div className="title">Banners</div>
      {/* <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search coupon by name, code, or discount value"
          value={searchQuery}
          //   onChange={(e) => handleSearch(e.target.value)}
        />
      </div> */}

      <TableComponent columns={columns} data={filteredBanners} />

      {/* Floating Action Button */}
      <div
        onClick={() => {
          openModal();
          setIsEdit(true);
        }}
        className="fixed top-8 right-8 bg-yellow-500 text-white p-2 rounded-full cursor-pointer shadow-lg hover:bg-yellow-600"
      >
        <MdAdd />
      </div>

      {/* Create Coupon Modal */}
      <BannerModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        isEdit={isEdit}
        editData={editData}
      />
    </div>
  );
};

export default TrainingVideo;
