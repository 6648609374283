import { BASE_URL } from "../config";
import { errorToast, warningToast } from "../utils/toast.util";

let TOKEN = localStorage.getItem("jwt");
// Add an event listener for the `storage` event
window.addEventListener("storage", (event) => {
  // Check if the `token` key has changed
  if (event.key === "jwt") {
    // Update the `TOKEN` variable
    TOKEN = event.newValue;
  }
});

const handleResponse = async (response: any) => {
  if (!response.ok) {
    const responseBody = await response.text();
    const parsedBody = JSON.parse(responseBody);
    const { message, messages } = parsedBody;

    if (response.status === 401) {
      // errorToast(message || 'Unauthorized, please log in again');
      errorToast(message || "Unauthorized, please log in again");
      throw message || parsedBody;
    } else if (response.status === 404) {
      // errorToast(message || "Resource not found");
      errorToast(message || "Resource not found");
      throw message || parsedBody;
    } else {
      if (!messages) {
        // errorToast(message || "Something went wrong: please try again later");
        errorToast(message || "Something went wrong: please try again later");
      }
      throw message || parsedBody;
    }
  } else {
    return response.json();
  }
};

export const get = async (
  path: string,
  params: { [key: string]: any } = {}
) => {
  const url = new URL(BASE_URL + path);
  TOKEN = localStorage.getItem("jwt");
  if (!params) {
    params = {};
  }
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  };

  try {
    const response = await fetch(url, requestOptions);

    const handledResponse = await handleResponse(response);
    console.log(handleResponse);

    return handledResponse;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      // errorToast("Server connection failed: Please check your internet connection");
      warningToast(
        "Server connection failed: Please check your internet connection"
      );
    }
    throw error;
  }
};

export const post = async (path: string, data: any) => {
  const url = new URL(BASE_URL + path);
  TOKEN = localStorage.getItem("jwt");
  const requestOptions: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, requestOptions);
    const handledResponse = await handleResponse(response);

    return handledResponse;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      // errorToast("Server connection failed: Please check your internet connection");
      warningToast(
        "Server connection failed: Please check your internet connection"
      );
    }
    throw error;
  }
};

export const put = async (path: string, data?: any) => {
  const url = new URL(BASE_URL + path);
  const TOKEN = localStorage.getItem("jwt");
  const requestOptions: RequestInit = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, requestOptions);

    const handledResponse = await handleResponse(response);

    return handledResponse;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      // errorToast("Server connection failed: Please check your internet connection");
      warningToast(
        "Server connection failed: Please check your internet connection"
      );
    }
    throw error;
  }
};

export const remove = async (path: string, data?: any) => {
  const url = new URL(BASE_URL + path);
  const TOKEN = localStorage.getItem("jwt");
  const requestOptions: RequestInit = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, requestOptions);

    const handledResponse = await handleResponse(response);

    return handledResponse;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      // errorToast("Server connection failed: Please check your internet connection");
      warningToast(
        "Server connection failed: Please check your internet connection"
      );
    }
    throw error;
  }
};

export const formDataApiCall = async (
  method: string,
  path: string,
  data: any
) => {
  const url = new URL(BASE_URL + path);
  TOKEN = localStorage.getItem("jwt");
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
    body: data,
  };
  try {
    const response = await fetch(url, requestOptions);

    const handledResponse = await handleResponse(response);

    return handledResponse;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      // errorToast("Server connection failed: Please check your internet connection");
      warningToast(
        "Server connection failed: Please check your internet connection"
      );
    }
    throw error;
  }
};
