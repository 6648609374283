import { PageType } from "../../../enums/page-type.enum";
import PageContent from "../components/PageContent";

const TermsConditionsForTeachers = () => {
  return (
    <PageContent
      page={PageType.TERMS_CONDITIONS_FOR_TEACHERS}
      title="Terms and Conditions content for teachers"
    />
  );
};

export default TermsConditionsForTeachers;
