import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminInterface } from "../../interfaces/admin.interface";
import { get, post, put } from "../ApiHelper";

interface AdminState {
  signedAdmin: {
    email: string;
    role: string;
  };
  status: string;
  adminList: AdminInterface[];
}

const initialState: AdminState = {
  signedAdmin: {
    email: "",
    role: "",
  },
  status: "idle",
  adminList: [],
};

export const getAdmins = createAsyncThunk("get-admins", async () => {
  try {
    const response = await get("/admin/get-all");
    return response;
  } catch (error) {
    throw error;
  }
});

export const createAdmin = createAsyncThunk(
  "create-admin",
  async (data: AdminInterface) => {
    try {
      const response = await post("/admin/admin/create", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "update-admin",
  async ({ id, data }: { id: string; data: any }) => {
    try {
      const response = await put(`/admin/admin/update/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const AdminSlice = createSlice({
  name: "tuition-request",
  initialState,
  reducers: {
    // setAdminModalState(state, action) {
    //   state.adminModalState = action.payload; // Define the updated state using that type
    //   // state.adminModalState = AdminData.find(obj => obj.id.toString() === action.payload)?? undefined;
    // },
    setSignedAdmin(
      state,
      action: PayloadAction<{
        email: string;
        role: string;
      }>
    ) {
      state.signedAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //get all admins
      .addCase(getAdmins.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.adminList = action.payload;

        state.status = "success";
      })
      .addCase(getAdmins.rejected, (state, action) => {
        state.status = "reject";
      })

      //create admin
      .addCase(createAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.adminList.push(action.payload);

        state.status = "success";
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.status = "reject";
      })

      //update admin
      .addCase(updateAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.adminList = state.adminList.map((admin) =>
          admin._id === action.payload._id ? action.payload : admin
        );

        state.status = "success";
      })

      .addCase(updateAdmin.rejected, (state, action) => {
        state.status = "reject";
      });
  },
});

// export the reducer that you can import it and get the value anywhere
export const { setSignedAdmin } = AdminSlice.actions;

export default AdminSlice.reducer;
