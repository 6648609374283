import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { TeacherInterface } from "../../../interfaces/teacher.interface";
import { put } from "../../../redux/ApiHelper";

const TeacherStatusHandler = ({ teacher }: { teacher: TeacherInterface }) => {
  const [isActive, setIsActive] = useState(teacher.isActive);
  const [openDialog, setOpenDialog] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState<string>("");

  useEffect(()=>{
    setIsActive(teacher.isActive)
  },[teacher])

  const handleStatusChange = (checked: boolean) => {
    if (!checked) {
      // If deactivating, open the dialog
      setOpenDialog(true);
    } else {
      // Activating the account
      updateTeacherStatus(checked, "");
    }
  };

  const updateTeacherStatus = async (status: boolean, reason: string) => {
    try {
      await put(`/admin/teacher/update/${teacher._id}`, {
        isActive: status,
        deactivationReason: reason,
      }).then((res) => {
        // console.log(res);

        setIsActive(true);
      });
    } catch (error) {}

    // console.log(teacher._id);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDeactivationReason("");
  };

  const handleReasonSubmit = async () => {
    try {
      await put(`/admin/teacher/update/${teacher._id}`, {
        isActive: false,
        deactivationReason,
      }).then((res) => {
        setIsActive(false);
        setOpenDialog(false);
      });
    } catch (error) {}
  };
  const isSubmitDisabled = deactivationReason.trim().length === 0;

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={isActive}
            onChange={(e) => handleStatusChange(e.target.checked)}
          />
        }
        label={isActive ? "Active" : "Inactive"}
      />

      {/* Deactivation Reason Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Deactivate Account</DialogTitle>
        <div className="py-2 px-6">
          <textarea
            className="w-full border rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            rows={4}
            placeholder="Reason for deactivation"
            value={deactivationReason}
            onChange={(e) => setDeactivationReason(e.target.value)}
          ></textarea>
        </div>
        <DialogActions>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={handleDialogClose}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 text-white rounded ${
              isSubmitDisabled
                ? "bg-blue-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
            onClick={handleReasonSubmit}
            disabled={isSubmitDisabled}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeacherStatusHandler;
