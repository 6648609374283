import { useEffect, useState } from "react";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  createButton,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import "./PageContent.scss";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../redux/slices/WindowSlice";
import { get, post, put } from "../../../redux/ApiHelper";
import { PageType } from "../../../enums/page-type.enum";
import { SketchPicker } from "react-color";

const BtnAlignLeft = createButton("Align left", "<≡", "justifyLeft");
const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");
const BtnAlignRight = createButton("Align right", "≡>", "justifyRight");

const PageContent = ({ page, title }: { page: PageType; title: string }) => {
  const [color, setColor] = useState<string>("#000000");
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const BtnColor: any = createButton("Set Color", "🎨", () =>
    document.execCommand("foreColor", false, color)
  );

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   (async () => {
  //     const res = await get("/admin/page-content/" + page);
  //     setValue(res.content);
  //   })();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    const fetchText = async () => {
      try {
        const res = await get("/admin/page-content/" + page);
        const response = await fetch(res.content);
        if (!response.ok) throw new Error("Failed to fetch");
        const data = await response.text();
        setValue(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchText();
  }, []);

  const onChange = (e: any) => setValue(e.target.value);

  const uploadHtmlAsTxt = async (htmlString: string) => {
    const textContent = htmlString; // Keep it as plain text

    try {
      // Step 1: Get a Presigned URL for a `.txt` file
      const res = await get(
        `/s3/generate-presigned-url?fileName=${page}.txt&fileType=text/plain&fileCategory=${page}`
      );

      const { url } = res;

      // Step 2: Upload the text file to S3
      await fetch(url, {
        method: "PUT",
        body: textContent, // Send plain text instead of JSON
        headers: {
          "Content-Type": "text/plain",
        },
      });
      return url.split("?")[0]; // Return the uploaded file URL
    } catch (error) {
      console.log("Error uploading TXT file:", error);
    }
  };

  const save = async () => {
    if (!value?.trim()) {
      console.warn("Content is empty. Aborting request.");
      return; // Prevent sending an empty request
    }

    dispatch(startLoading());

    try {
      const uploadedUrl = await uploadHtmlAsTxt(value);
      await put(`/admin/page-content/${page}`, {
        content: String(uploadedUrl),
      });
    } catch (error) {
      console.error("Failed to save content:", error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleColorChange = (color: any) => {
    setColor(color.hex);
    togglePicker();
  };

  return (
    <div className="page-content">
      <h1
        style={{
          color: "gray",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "16px",
        }}
      >
        {title}
      </h1>
      <EditorProvider>
        <Editor
          value={value}
          onChange={onChange}
          containerProps={{ style: { textAlign: "left", maxHeight: "400px" } }}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnNumberedList />
            <BtnBulletList />
            <Separator />
            <BtnLink />
            <BtnClearFormatting />
            <HtmlButton />
            <Separator />
            <BtnAlignLeft />
            <BtnAlignCenter />
            <BtnAlignRight />
            <BtnColor onClick={togglePicker} />
            <BtnStyles />
            <button
              onClick={save}
              style={{ float: "right" }}
              className="px-2 py-0 mr-2 ml-auto text-gray-700 bg-yellow-400 rounded-md hover:bg-yellow-600 transition"
            >
              Save
            </button>
          </Toolbar>
        </Editor>
      </EditorProvider>

      {value ? (
        <div className="content" dangerouslySetInnerHTML={{ __html: value }} />
      ) : null}

      <div
        style={{ display: showPicker ? "block" : "none" }}
        className="color-picker-container"
      >
        <SketchPicker
          className="color-picker"
          color={color}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default PageContent;
