import React, { useEffect, useState } from "react";
import { Column } from "../../../interfaces/table.interface";
import TableComponent from "../../../components/table/TableComponent";
import { get, put } from "../../../redux/ApiHelper";
import { TuitionJobPostInterface } from "../../../interfaces/tuitionJobPosts.interface";
import { errorToast, successToast } from "../../../utils/toast.util";

const TuitionJobPostsPage = () => {
  const [jobPosts, setJobPosts] = useState<TuitionJobPostInterface[]>([]);
  const [filteredJobPosts, setFilteredJobPosts] = useState<
    TuitionJobPostInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Fetch tuition job posts on component mount
  useEffect(() => {
    get(`/tuition-job-post/all`).then((res) => {
      setJobPosts(res.data);
      setFilteredJobPosts(res.data);
    });
  }, []);

  // Search functionality
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = jobPosts.filter((post) => {
      return (
        post.subject.toLowerCase().includes(lowerQuery) ||
        post.description?.toLowerCase().includes(lowerQuery) ||
        post.location.toLowerCase().includes(lowerQuery) ||
        post.gcChargePerClass.coin.toString().includes(lowerQuery) ||
        post.parent?.name.toLowerCase().includes(lowerQuery) ||
        post.student?.name.toLowerCase().includes(lowerQuery) ||
        post.status.toLowerCase().includes(lowerQuery)
      );
    });

    setFilteredJobPosts(filteredList);
  };

  // Handle updating the status to "Active"
  const handleStatusUpdate = (jobId: string, status: "active" | "rejected") => {
    put(`/tuition-job-post/status/${jobId}`, { status })
      .then((updatedPost) => {
        successToast("Status updated to Active!");

        // Update local state with the updated job post
        setJobPosts((prev) =>
          prev.map((post) =>
            post._id === updatedPost._id ? updatedPost : post
          )
        );
        setFilteredJobPosts((prev) =>
          prev.map((post) =>
            post._id === updatedPost._id ? updatedPost : post
          )
        );
      })
      .catch((error) => {
        errorToast("Failed to update status. Please try again.");
      });
  };

  const columns: Column[] = [
    { select: "_id", header: "Job ID", align: "left" },
    { select: "subject", header: "Subject" },
    { select: "description", header: "Description" },
    { select: "location", header: "Location" },
    {
      header: "GC Charge (Coin)",
      render: (_value: any, row: TuitionJobPostInterface) =>
        row?.gcChargePerClass?.coin || "N/A",
    },
    {
      header: "Parent Name",
      render: (_value: any, row: TuitionJobPostInterface) =>
        row?.parent?.name || "N/A",
    },
    {
      header: "Student Name",
      render: (_value: any, row: TuitionJobPostInterface) =>
        row?.student?.name || "N/A",
    },
    // { select: "status", header: "Status" },
    {
      header: "Status",
      render: (_value: any, row: TuitionJobPostInterface) => (
        <div className="flex gap-2">
          {row.status === "pending" ? (
            <>
              <button
                className="px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700"
                onClick={() => handleStatusUpdate(row._id, "active")}
              >
                Approve
              </button>
              <button
                className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
                onClick={() => handleStatusUpdate(row._id, "rejected")}
              >
                Reject
              </button>
            </>
          ) : (
            <span
              className={`px-4 py-2 mx-auto  ${
                row.status === "active"
                  ? "text-green-600 "
                  : row?.status === "closed"
                  ? "text-yellow-500"
                  : "text-red-600"
              }`}
            >
              {row.status === "active" ? "Active" : "Rejected"}
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="tuitionJobPosts table-container">
      <div className="title">Tuition Job Posts</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search job posts"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredJobPosts} />
    </div>
  );
};

export default TuitionJobPostsPage;
