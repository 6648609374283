import { useEffect, useState } from "react";
import { MdEdit, MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";
import TableComponent from "../../components/table/TableComponent";
import { DispatchType } from "../../interfaces/redux.interface";
import { Column } from "../../interfaces/table.interface";
import { TrainingVideoInterface } from "../../interfaces/trainingVideo.interface";
import { get } from "../../redux/ApiHelper";
import CreateTrainingVideoModal from "./CreateTrainingVideoModal";

const TrainingVideo = () => {
  const dispatch: DispatchType = useDispatch();
  //    const { couponList }: { couponList: CouponInterface[] } = useSelector(
  //      (state: RootState) => state.coupon
  //    );
  //   const [trainingVideos, setTrainingVideos] = useState<
  //     TrainingVideoInterface[]
  //   >([]);
  const [filteredTrainingVideo, setFilteredTrainingVideo] = useState<
    TrainingVideoInterface[]
  >([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<TrainingVideoInterface>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    // dispatch(getCoupons());
    // eslint-disable-next-line
    const getTrainingVideos = async () => {
      dispatch(startLoading());
      await get("/admin/training-video/all")
        .then((response) => {
          setFilteredTrainingVideo(response.data);
        })
        .catch((error) => {})
        .finally(() => {
          dispatch(stopLoading());
        });
    };
    getTrainingVideos();
  }, [isEdit]);

  useEffect(() => {
    setFilteredTrainingVideo(filteredTrainingVideo);
  }, [filteredTrainingVideo]);

  //   const handleSearch = (query: string) => {
  //     setSearchQuery(query);
  //     const lowerQuery = query.toLowerCase();

  //     const filteredList = couponList.filter((coupon) => {
  //       return (
  //         coupon._id?.toLowerCase().includes(lowerQuery) || // Search by ID
  //         coupon.couponCode?.toLowerCase().includes(lowerQuery) || // Search by Coupon Code
  //         coupon.discountType?.toLowerCase().includes(lowerQuery) || // Search by Discount Type
  //         (coupon.discountValue?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Discount Value
  //         coupon.expiryDate?.toLowerCase().includes(lowerQuery) || // Search by Expiry Date
  //         (coupon.usageLimit?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Usage Limit
  //         (coupon.remainingUses?.toString().toLowerCase() || "").includes(
  //           lowerQuery
  //         ) || // Search by Remaining Uses
  //         (coupon.isActive ? "active" : "inactive").includes(lowerQuery) // Search by Status
  //       );
  //     });

  //     setFilteredCouponList(filteredList);
  //   };

  // Function to open modal
  const openModal = () => {
    setIsEdit(true);
    setIsModalOpen(true);
  };

  //   Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null);
    setIsEdit(false);
  };

  const columns: Column[] = [
    { select: "title", header: "Title", align: "left" },
    { select: "forWhom", header: "Tutorial is for" },
    { select: "description", header: "Video description" },
    { select: "videoUrl", header: "Video Url" },
    {
      header: "Edit",
      render: (value: boolean, rowData: TrainingVideoInterface) => (
        <div
          className="rounded-full bg-slate-200 p-2 text-yellow-500 cursor-pointer"
          onClick={() => {
            setEditData(rowData);
            setIsEdit(true);
            setIsModalOpen(true);
          }}
        >
          <MdEdit className="m-auto" />
        </div>
      ),
    },
  ];

  return (
    <div className="coupon table-container">
      <div className="title">Training videos</div>
      <div className="search-container mb-4">
        {/* <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search coupon by name, code, or discount value"
          value={searchQuery}
          //   onChange={(e) => handleSearch(e.target.value)}
        /> */}
      </div>

      <TableComponent columns={columns} data={filteredTrainingVideo} />

      {/* Floating Action Button */}
      <div
        onClick={openModal}
        className="fixed top-8 right-8 bg-yellow-500 text-white p-2 rounded-full cursor-pointer shadow-lg hover:bg-yellow-600"
      >
        <MdAdd />
      </div>

      {/* Create Coupon Modal */}
      <CreateTrainingVideoModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        isEdit={isEdit}
        editData={editData}
      />
    </div>
  );
};

export default TrainingVideo;
