import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../interfaces/redux.interface";
import { TrainingVideoInterface } from "../../interfaces/trainingVideo.interface";
import { post, put } from "../../redux/ApiHelper";

const CreateTrainingVideoModal = ({
  isOpen,
  closeModal,
  isEdit,
  editData,
}: {
  isOpen: boolean;
  closeModal: () => void;
  isEdit?: boolean;
  editData?: TrainingVideoInterface;
}) => {
  const dispatch: DispatchType = useDispatch();
  const [trainingVideoData, setTrainingVideoData] =
    useState<TrainingVideoInterface>({
      forWhom: "Parent",
      title: "",
      description: "",
      videoUrl: "",
      iframe: "",
    });
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState({
    forWhom: "Parent",
    title: "",
    description: "",
    // videoUrl: "",
    // iframe: "",
  });

  useEffect(() => {
    if (isEdit && editData) {
      setTrainingVideoData({
        ...editData,
      });
    } else {
      setTrainingVideoData({
        forWhom: "Parent",
        title: "",
        description: "",
        videoUrl: "",
        iframe: "",
      });
    }
  }, [isEdit, editData]);

  useEffect(() => {
    const { forWhom, title, description, videoUrl, iframe } = trainingVideoData;

    const isDisabled =
      !forWhom || // Check if couponCode is empty
      !title || // Check if discountType is empty
      !description ||
      !videoUrl;

    setIsDisabled(isDisabled);
    // Update error messages
    const errors = {
      title: !title ? "Title is required" : "",
      forWhom: !forWhom ? "forWhom is required" : "",
      description: !description ? "description is required" : "",
      //   videoUrl: !videoUrl ? "videoUrl is required" : "",
      //   iframe: !iframe ? "iframe is required" : "",
    };

    setErrors(errors);
  }, [trainingVideoData]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setTrainingVideoData({
      ...trainingVideoData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (isEdit && editData) {
      await put("/admin/training-video/update", trainingVideoData)
        .then((res) => {
          closeModal();
        })
        .finally(() => {});
    } else {
      await post("/admin/training-video/create", trainingVideoData)
        .then((res) => {
          closeModal();
        })
        .finally(() => {});
    }
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg max-h-screen overflow-auto">
        {/* Header */}
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {isEdit && editData ? "Update" : "Create New"} Training video
          </h2>
        </div>

        {/* Input Fields */}
        <div className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-left text-gray-700">
              Enter Training video title / frequently asked question
            </label>
            <input
              type="text"
              name="title"
              value={trainingVideoData.title}
              onChange={handleChange}
              placeholder="Video title"
              className={`w-full px-4 py-2 border ${
                errors.title ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.title && (
              <span className="text-red-500 text-sm text-left pl-1">
                {errors.title}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-left text-gray-700"
            >
              Description of the video
            </label>
            <textarea
              name="description"
              value={trainingVideoData.description}
              onChange={handleChange}
              placeholder="Video description"
              className={`w-full px-4 py-2 border ${
                errors.description ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.description && (
              <span className="text-red-500 text-sm text-left pl-1">
                {errors.description}
              </span>
            )}
          </div>

          <div>
            <label htmlFor="videoUrl" className="block text-left text-gray-700">
              Video url
            </label>
            <input
              type="text"
              name="videoUrl"
              value={trainingVideoData.videoUrl}
              onChange={handleChange}
              placeholder="Paste video url"
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
          </div>
          <div>
            <label htmlFor="iframe" className="block text-left text-gray-700">
              Enter iframe tag
            </label>
            <textarea
              name="iframe"
              value={trainingVideoData.iframe}
              onChange={handleChange}
              placeholder="Paste Iframe code"
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
          </div>
          <div>
            <label
              htmlFor="forWhom"
              className="block text-left text-gray-700"
            >
              Training video for parents or teachers
            </label>
            <select
              name="forWhom"
              value={trainingVideoData.forWhom}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.forWhom ? "border-red-500" : "border-gray-300"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="Parent">Parent</option>
              <option value="Teacher">Teacher</option>
            </select>
            {errors.forWhom && (
              <span className="text-red-500 text-sm text-left pl-1">
                {errors.forWhom}
              </span>
            )}
          </div>
        </div>

        {/* Actions */}
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-yellow-600 rounded-md hover:bg-yellow-700 transition disabled:opacity-50"
            disabled={isDisabled}
          >
            {isEdit && editData ? "Update" : "Create"} training video
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTrainingVideoModal;
