import React, { useEffect, useState } from "react";
import { get, post } from "../../../redux/ApiHelper";
import { Column } from "../../../interfaces/table.interface";
import TableComponent from "../../../components/table/TableComponent";
import { formatDateTime } from "../../../utils/date&time.util";
import { PaymentInterface } from "../../../interfaces/payment.interface";
import { IconButton, Tooltip } from "@mui/material";
import { FaCopy } from "react-icons/fa";
import { errorToast, successToast } from "../../../utils/toast.util";
import { PayoutInterface } from "../../../interfaces/payout.interface";

const PayoutsPage = () => {
  const [payoutList, setPayoutList] = useState<PayoutInterface[]>([]);
  const [filteredPayoutList, setFilteredPayoutList] = useState<
    PayoutInterface[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [verifyingMap, setVerifyingMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    get("/admin/payout/all").then((res) => {
      console.log(res.payouts);
      setPayoutList(res.payouts);
      setFilteredPayoutList(res.payouts);
    });
  }, []);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();

    const filteredList = payoutList?.filter((payout) => {
      const payoutId = payout._id || "";
      const teacherId = payout.teacherId._id || "";
      const teacherName = payout.teacherId.name?.toLowerCase() || "";
      const studentName = payout.studentId.name?.toLowerCase() || "";
      const email = payout.teacherId.email?.toLowerCase() || "";
      const payoutDate = formatDateTime(payout?.createdAt);

      return (
        String(payout.withdrawableINR).includes(lowerQuery) ||
        studentName.includes(lowerQuery) ||
        payoutId.includes(lowerQuery) ||
        teacherId.includes(lowerQuery) ||
        email.includes(lowerQuery) ||
        payoutDate.toLowerCase().includes(lowerQuery) ||
        teacherName.includes(lowerQuery)
      );
    });

    setFilteredPayoutList(filteredList);
  };

  const columns: Column[] = [
    { select: "_id", header: "Payout ID", align: "left" },
    { select: "withdrawableINR", header: "INR amount" },
    // { select: "currency", header: "Currency" },
    {
      header: "Student name",
      render: (_value: any, row: PayoutInterface) =>
        row?.studentId.name || "N/A",
    },
    {
      header: "Teacher name",
      render: (_value: any, row: PayoutInterface) =>
        row?.teacherId.name || "N/A",
    },
    {
      header: "Teacher email",
      render: (_value: any, row: PayoutInterface) =>
        row?.teacherId.email || "N/A",
    },
    {
      header: "Teacher phone",
      render: (_value: any, row: PayoutInterface) =>
        row?.teacherId.phoneNumber || "N/A",
    },
    {
      header: "CreatedAt",
      render: (_value: any, row: PayoutInterface) =>
        new Date(row?.createdAt).toLocaleString() || "N/A",
    },
    {
      header: "Status",
      render: (_value: any, row: PayoutInterface) => {
        const isCompleted = row?.adminAction === "Pending";
        return (
          <div className="flex items-center">
            <span
              className={`status-badge ${
                !isCompleted
                  ? "bg-green-500"
                  : row?.adminAction === "Pending"
                  ? "bg-yellow-500"
                  : "bg-red-500"
              } text-white px-2 py-1 rounded`}
            >
              {row?.adminAction}
            </span>{" "}
          </div>
        );
      },
    },
    {
      header: "Action",
      render: (_value: any, row: PayoutInterface) => {
        const isProcessing = verifyingMap[row._id];

        const handleRelease = async () => {
          setVerifyingMap((prev) => ({ ...prev, [row._id]: true }));
          post(`/admin/payout/request-action`, {
            teacherId: row.teacherId._id,
            payoutId: row._id,
            action: "Accept",
          })
            .then((response) => {
              const { payout, message } = response;

              const updatedList = payoutList.map((OldPayout) =>
                OldPayout._id === row._id
                  ? { ...OldPayout, ...payout }
                  : OldPayout
              );
              setPayoutList(updatedList);
              setFilteredPayoutList(updatedList);
              successToast(message || "Payment release successfully");
            })
            .catch(() =>
              errorToast("Failed to proceed payment. Please try again.")
            )
            .finally(() =>
              setVerifyingMap((prev) => ({ ...prev, [row._id]: false }))
            );
        };

        return (
          <div className="flex items-center">
            {row?.adminAction === "Pending" && (
              <div className="ml-2">
                <Tooltip
                  title="Click release to procceed the transaction"
                  placement="top"
                >
                  <button
                    className={`text-white px-3 py-1 rounded ${
                      isProcessing
                        ? "bg-blue-300 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                    onClick={handleRelease}
                    disabled={isProcessing}
                    aria-label="Release Payment"
                  >
                    {isProcessing ? "Releasing..." : "Release"}
                  </button>
                </Tooltip>
                {/* <Tooltip title="Click reject the transaction" placement="top">
                  <button
                    className={`text-white px-3 py-1 rounded ${
                      isProcessing
                        ? "bg-blue-300 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                    onClick={handleRelease}
                    disabled={isProcessing}
                    aria-label="Release Payment"
                  >
                    {isProcessing ? "Releasing..." : "Release"}
                  </button>
                </Tooltip> */}
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   header: "Transaction ID",
    //   render: (_value: any, row: PaymentInterface) =>
    //     row?.transactionId || "N/A",
    // },
    // {
    //   header: "External Transaction ID",
    //   render: (_value: any, row: PaymentInterface) => {
    //     const transactionId = row?.externalTransactionId || "N/A";

    //     if (transactionId === "N/A") {
    //       return transactionId;
    //     }

    //     const handleCopy = () => {
    //       navigator.clipboard.writeText(transactionId);
    //       successToast("Transaction ID copied to clipboard!");
    //     };

    //     return (
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <span style={{ marginRight: 8 }}>
    //           {transactionId.slice(0, 10)}...
    //         </span>
    //         <Tooltip title="Copy Transaction ID">
    //           <IconButton
    //             onClick={handleCopy}
    //             size="small"
    //             aria-label="Copy Transaction ID"
    //           >
    //             <FaCopy />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   header: "Date & Time",
    //   render: (_value: any, row: PaymentInterface) =>
    //     formatDateTime(row?.createdAt),
    // },
  ];

  return (
    <div className="paymentsPage table-container">
      <div className="title">Payouts</div>
      <div className="search-container mb-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-gray-700"
          placeholder="Search payments"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <TableComponent columns={columns} data={filteredPayoutList} />
    </div>
  );
};

export default PayoutsPage;
