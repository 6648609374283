import React, { useEffect, useState } from "react";
import { BannerInterface } from "../../interfaces/banner.interface";
import { BASE_URL } from "../../config";

const BannerModal = ({
  isOpen,
  closeModal,
  isEdit,
  editData,
}: {
  isOpen: boolean;
  closeModal: () => void;
  isEdit?: boolean;
  editData?: BannerInterface;
}) => {
  const [bannerData, setBannerData] = useState({
    forWhom: "Parent",
    description: "",
    imageUrlMobile: null as File | null,
    imageUrlDesktop: null as File | null,
    redirectUrl: "",
  });

  useEffect(() => {
    if (isEdit && editData) {
      setBannerData({
        ...editData,
        imageUrlMobile: null,
        imageUrlDesktop: null,
      });
    } else {
      setBannerData({
        forWhom: "Parent",
        description: "",
        imageUrlMobile: null as File | null,
        imageUrlDesktop: null as File | null,
        redirectUrl: "",
      });
    }
  }, [isEdit, editData]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = (e.target as HTMLInputElement).files?.[0] || null;
      setBannerData((prev) => ({ ...prev, [name]: file }));
    } else {
      setBannerData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("forWhom", bannerData.forWhom);
    formData.append("description", bannerData.description);
    formData.append("redirectUrl", bannerData.redirectUrl);

    if (bannerData.imageUrlMobile)
      formData.append("imageUrlMobile", bannerData.imageUrlMobile);
    if (bannerData.imageUrlDesktop)
      formData.append("imageUrlDesktop", bannerData.imageUrlDesktop);

    if (isEdit && editData) {
      //   await put("/admin/banner/update", formData, { headers: { "Content-Type": "multipart/form-data" } });
      const res = await fetch(
        `${BASE_URL}/admin/banner/update/${editData._id}`,
        {
          method: "PUT",
          body: formData, // No need to set "Content-Type", fetch automatically sets it for FormData
        }
      );
      const data = res.json();
    } else {
      const res = await fetch(`${BASE_URL}/admin/banner/create`, {
        method: "POST",
        body: formData, // No need to set "Content-Type", fetch automatically sets it for FormData
      });
      const data = res.json();
    }

    closeModal();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg mx-4 p-6 rounded-lg shadow-lg max-h-screen overflow-auto">
        <h2 className="text-xl font-semibold text-gray-800">
          {isEdit ? "Update" : "Create New"} Banner
        </h2>

        <div className="space-y-4">
          <div>
            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={bannerData.description}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-gray-700">Redirect URL</label>
            <input
              name="redirectUrl"
              value={bannerData.redirectUrl}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-gray-700">For Whom</label>
            <select
              name="forWhom"
              value={bannerData.forWhom}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            >
              <option value="Parent">Parent</option>
              <option value="Teacher">Teacher</option>
            </select>
          </div>

          {/* Image Upload Fields */}
          <div>
            <label className="block text-gray-700">Mobile Banner Image</label>
            <input
              type="file"
              name="imageUrlMobile"
              onChange={handleChange}
              className="w-full border"
            />
          </div>

          <div>
            <label className="block text-gray-700">Desktop Banner Image</label>
            <input
              type="file"
              name="imageUrlDesktop"
              onChange={handleChange}
              className="w-full border"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700"
          >
            {isEdit ? "Update" : "Create"} Banner
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerModal;
